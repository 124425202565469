<template>
  <div id="cms-cash-count-edit">
    <b-container fluid>
      <b-row>
        <b-col></b-col>
        <b-col><h2 style="color: white; margin-bottom: 1em">Cash Count - Edit</h2></b-col>
        <b-col></b-col>
      </b-row>
      <b-row>
        <b-col>
          <SideMenu></SideMenu>
        </b-col>
        <b-col class="col-sm-9">
          <div v-if="loading" class="text-center">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
          <div v-else class="col-sm-9" style="color: white; text-align: right">
            <b-row>
              <b-col class="col-sm-5" style="margin-left: 6em">
                <b-form-group
                        label-cols-sm="1"
                        label-cols-lg="1"
                        label="Date:"
                        label-for="date"
                >
                  <b-form-input id="date" type="date" v-model="cashCount.date" style="margin-left: 2em"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€200:"
                      label-for="two-hundred"
              >
                <b-form-input id="two-hundred" type="number" v-model="cashCount.two_hundred" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€2.00:"
                      label-for="two"
              >
                <b-form-input id="two" type="number" v-model="cashCount.two" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€100:"
                      label-for="hundred"
              >
                <b-form-input id="hundred" type="number" v-model="cashCount.hundred" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€1.00:"
                      label-for="one"
              >
                <b-form-input id="one" type="number" v-model="cashCount.one" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 50 :"
                      label-for="fifty"
              >
                <b-form-input id="fifty" type="number" v-model="cashCount.fifty" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.50:"
                      label-for="fifty_cent"
              >
                <b-form-input id="fifty_cent" type="number" v-model="cashCount.fifty_cent" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 20 :"
                      label-for="twenty"
              >
                <b-form-input id="fifty" type="number" v-model="cashCount.twenty" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.20:"
                      label-for="twenty_cent"
              >
                <b-form-input id="twenty_cent" type="number" v-model="cashCount.twenty_cent" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€ 10 :"
                      label-for="ten"
              >
                <b-form-input id="ten" type="number" v-model="cashCount.ten" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.10:"
                      label-for="ten_cent"
              >
                <b-form-input id="ten_cent" type="number" v-model="cashCount.ten_cent" @change="updateTotalCashCount()"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label=" € 5 :"
                      label-for="five"
              >
                <b-form-input id="five" type="number" v-model="cashCount.five" @change="updateTotalCashCount()" style="margin-left: 0.2em" ></b-form-input>
              </b-form-group>
              <b-form-group
                      label-cols-sm="8"
                      label-cols-lg="8"
                      label="€0.05:"
                      label-for="five_cent"
              >
                <b-form-input id="five_cent" type="number" v-model="cashCount.five_cent" @change="updateTotalCashCount()" style="margin-left: 0.2em"></b-form-input>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group
                      label-cols-sm="7"
                      label-cols-lg="7"
                      label=" Total € :"
                      label-for="total"
                      style="margin-right: 4.5em"
              >
                <b-form-input
                        id="total"
                        type="number"
                        v-model="cashCount.total"
                        style="margin-left: 0.2em"
                        disabled
                ></b-form-input>
              </b-form-group>
              <router-link class="link-color" to="/cms/cash-count" style="margin-right: 0.1em" @click.prevent>
                <b-button type="reset" variant="danger">Cancel</b-button>
              </router-link>
              <router-link class="link-color" to="" @click.prevent>
                <b-button  @click.prevent="saveCashCount()" type="submit" variant="success">Submit</b-button>
              </router-link>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import SideMenu from '@/components/SideMenu.vue'
  import axios from "axios";

  export default {
    data() {
      return {
        loading: true,
        cashCount: [],
      }
    },
    components: {
      SideMenu
    },
    beforeCreate () {
      this.axios
          .get(`https://batavia-backend.herokuapp.com/api/cash-count/${this.$route.params.id}`)
          .then(response => (
              this.cashCount = response.data,
              this.cashCount['total'] = this.cashCount['total'].toFixed(2)
          ))
          .finally(() => {
            this.loading = false
          })
    },
    methods: {
      getTotalCashCount() {
        let total = 0.00

        total += (0.05 * this.cashCount.five_cent)
        total += (0.10 * this.cashCount.ten_cent)
        total += (0.20 * this.cashCount.twenty_cent)
        total += (0.50 * this.cashCount.fifty_cent)
        total += (1.00 * this.cashCount.one)
        total += (2.00 * this.cashCount.two)
        total += (5.00 * this.cashCount.five)
        total += (10.00 * this.cashCount.ten)
        total += (20.00 * this.cashCount.twenty)
        total += (50.00 * this.cashCount.fifty)
        total += (100.00 * this.cashCount.hundred)
        total += (200.00 * this.cashCount.two_hundred)
        total += (500.00 * this.cashCount.five_hundred)

        return total.toFixed(2)
      },
      updateTotalCashCount() {
        this.cashCount['total'] = this.getTotalCashCount()
      },
      saveCashCount() {
        this.axios
            .patch(
                `https://batavia-backend.herokuapp.com/api/cash-count/${this.$route.params.id}`,
                {
                  date: this.cashCount.date,
                  five_cent: this.cashCount.five_cent,
                  ten_cent: this.cashCount.ten_cent,
                  twenty_cent: this.cashCount.twenty_cent,
                  fifty_cent: this.cashCount.fifty_cent,
                  one: this.cashCount.one,
                  two: this.cashCount.two,
                  five: this.cashCount.five,
                  ten: this.cashCount.ten,
                  twenty: this.cashCount.twenty,
                  fifty: this.cashCount.fifty,
                  hundred: this.cashCount.hundred,
                  two_hundred: this.cashCount.two_hundred,
                  five_hundred: this.cashCount.five_hundred,
                  total: this.cashCount.total
                },
                {
                  headers: {
                    Authorization: axios.defaults.headers.common.Authorization
                  }
                }
            )
            .then(() => {
              this.$router.push({ name: 'CmsCashCounts' })
            })
      }
    }
  }
</script>